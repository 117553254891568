<template>
     <v-card class="w-full md:w-2/3 bg-white shadow-md rounded-sm h-auto sm:px-0 md:px-5">
        <v-card-title>
            <div class="flex gap-2 justify-between items-center">
                <div class="flex gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-gray-500">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z" />
                    </svg>
                    <span class="font-sans tracking-wide font-bold text-gray-500 text-base">INFORMACIÓN DE USUARIO</span>
                </div>
            </div>
        </v-card-title>
            <hr>
            <v-card-text>
                <div class="grid grid-cols-1 md:grid-cols-12 px-1 py-1 gap-5 items-center">
                 <div class="col-span-12 md:col-span-8">
                      <rb-input-group :label="'Correo'" :value="$v.user.email">
                        <template #input>
                            <rb-input :disabled="!isEditUser" :placeholder="'Ingrese el correo'" :value="user.email" v-model="$v.user.email.$model" email @input="v => user.email = v"></rb-input>
                        </template>
                      </rb-input-group>
                  </div>
                  <div class="col-span-12 md:col-span-4 flex items-end justify-items-end align-bottom justify-end h-full">
                        <rb-button :label="'Cambiar correo'" :css="'btn-full-gray'" class="flex justify-center gap-2 items-center">
                            <template #default>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
                                </svg>
                            </template>
                        </rb-button>
                    
                  </div>
                
             </div>
             <div class="grid grid-cols-1 md:grid-cols-12 px-1 py-1 gap-5 items-center">
                <div class="col-span-12 md:col-span-8">
                      <rb-input-group :label="'Contraseña'" :value="$v.user.password">
                        <template #input>
                            <rb-input disabled :placeholder="'Ingrese la contraseña'" :value="user.password" v-model="$v.user.password.$model" password @input="v => user.password = v"></rb-input>
                        </template>
                      </rb-input-group>
                  </div>
                  <div class="col-span-12 md:col-span-4 flex items-end justify-items-end align-bottom justify-end h-full">
                        <rb-button :label="'Cambiar contraseña'" :css="'btn-full-gray'" class="flex justify-center gap-2 items-center">
                            <template #default>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                </svg>
                            </template>
                        </rb-button>
                    
                  </div>
             </div>
            </v-card-text>

    </v-card>
</template>

<script>
import { validationMixin } from 'vuelidate';
import RBInputPrimaryGroup from '../../../@core/components/form/group/RBInputPrimaryGroup.vue';
import RBPrimaryButton from '../../../@core/components/form/button/RBPrimaryButton.vue';
import RBInput from '../../../@core/components/form/input/RBInput.vue';
import User from '../../../models/user/user.model';
import { ProfileService } from '../../../services/HSuite/Context/hsuite-rebyu-context';

export default {
    mixins: [validationMixin],
    data: function() {
        return {
            isEditUser: false,
            user: new User(),
           
        }
    },
    components: {
        'rb-input': RBInput,
        'rb-input-group': RBInputPrimaryGroup,
        'rb-button': RBPrimaryButton,
    },
    validations: {
            user: {
                email: {
                    required: false,
                },
                password: {
                    required: false
                }
            }
 
    },
    mounted() {
        this.init()
    },
    methods: {
        init: async function() {
            await this.getProfile()
        },
        getProfile: async function() {
            let response = await ProfileService.getByIdUser();
            if(response.success) {
                let {email} = response.data;
                this.user.email =  email;
                this.user.password = '*********************'
            }
        },

    },
    watch: {

    }
}
</script>

<style>

</style>