<template> 
         <div class="bg-gray-100">
          <v-loading :is_loading="isloading" />
          <div class="bg-gray-100 sm:px-3 md:px-10 sm:py-3 md:py-5 grid grid-flow-row md:grid-cols-12 gap-5 h-auto max-h-full min-screen-content" >
              <div class="col-span-12 md:col-span-3 sm:px-2 md:px-10 sm:py-2 md:py-4 bg-white rounded-md shadow-lg">
                  <div class="flex justify-end">
                      <div v-security="{acm: $route.meta.acm, acc: acc.EDIT_PROFILE}">
                        <rb-options :actions="actions" v-on:event="(event) => onEvent(event, item)">
                                <template #buttonsheet>
                                    <rb-button-options :bntcss="'w-full bg-white opacity-90 px-2 py-0.5 rounded-md justify-center'" :iconcss="'text-gray-400 cursor-pointer hover:text-gray-500 w-7 h-7'"/>
                                </template>
                                <template #buttonmenu>
                                    <rb-button-options :bntcss="'w-full bg-white opacity-90 px-2 py-0.5 rounded-md justify-center'" :iconcss="'text-gray-400 cursor-pointer hover:text-gray-500 w-7 h-7'"/>
                                </template>
                        </rb-options>
                      </div>
                  </div>
                  <div class="grid justify-center">
                      <div class="sm:h-36 md:h-52 sm:w-36 md:w-52 bg-gray-300 rounded-full flex justify-center items-center shadow-lg">
                          <div  class="sm:h-32 md:h-48 sm:w-32 md:w-48 bg-cover rounded-full relative" :style="`background-image: url('${profile.profilePicture ||  DEFAULT_IMG }')`">
                               <v-dialog 
                                    v-model="isprofilephoto"
                                    persistent
                                    max-width="700px"
                                    >
                                    <template  v-slot:activator="{ on, attrs }" >
                                        <div v-bind="attrs" v-on="on" class="w-12 h-12 rounded-full bg-gray-900  absolute bottom-1 -right-0 opacity-80"  v-security="{acm: $route.meta.acm, acc: acc.PROFILE_PHOTO}">
                                        <div class="h-12 flex justify-center items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </template>
                                     <v-user-picture :id_profile="profile.id" :height="500" :width="500" :open="isprofilephoto" v-on:close="isprofilephoto = false" v-on:success="onSuccessPicture" />
                               </v-dialog>
                          </div>
                      </div>
                  </div>
                  <div class="grid py-5 sm:ml-3 md:ml-0 sm:mr-3 md:mr-0">
                      <div class="grid">
                        <label class="font-sans tracking-wide text-4xl font-bold text-center">{{profile.name ? profile.name : ''}}</label>
                        <label class="font-sans tracking-wide text-fucsia-lighter font-semibold text-center">{{username.role ? username.role : ''}}</label>
                      </div>
                      <div class="grid gap-1 gap-y-2 bg-gray-100 mt-5 px-3">
                           <!-- <div>
                                <div>
                                    <small class="text-gray-400 tracking-wide">Información Personal</small>
                                </div>
                                <v-divider></v-divider>
                            </div>

                          <div class="grid grid-cols-1 md:grid-cols-2 items-center">
                              <label class="text-base font-sans font-bold">Nombre(s) </label>
                              <label class="text-base font-sans font-bold text-gray-500">{{profile.name ? profile.name : ''}} </label>
                          </div>
                          <div class="grid grid-cols-1 md:grid-cols-2 items-center">
                              <label class="text-base font-sans font-bold">Apellido Paterno </label>
                              <label class="text-base font-sans font-bold text-gray-500">{{profile.last_name ? profile.last_name : ''}} </label>
                          </div>
                           <div class="grid grid-cols-1 md:grid-cols-2 items-center">
                              <label class="text-base font-sans font-bold">Apellido Materno </label>
                              <label class="text-base font-sans font-bold text-gray-500">{{profile.second_lastname ? profile.second_lastname : ''}} </label>
                          </div>
                          <div class="grid grid-cols-1 md:grid-cols-2 items-center">
                              <label class="text-base font-sans font-bold">Genero </label>
                              <label class="text-base font-sans font-bold text-gray-500">{{profile.desc_sex ? profile.desc_sex : ''}} </label>
                          </div>
                           <div class="grid grid-cols-1 md:grid-cols-2 items-center">
                              <label class="text-base font-sans font-bold">Fecha de Nac </label>
                              <label class="text-base font-sans font-bold text-gray-500">{{profile.birthdate | formatDate}} </label>
                          </div> -->

                           <div>
                                <div>
                                    <small class="text-gray-400 tracking-wide">Información de Contacto</small>
                                </div>
                                <v-divider></v-divider>
                            </div>
                            <div class="grid grid-cols-1 md:grid-cols-2 items-center">
                              <label class="text-base font-sans font-bold">Teléfono: </label>
                              <label class="text-base font-sans font-bold text-gray-500">{{profile.phone ? profile.phone : ''}} </label>
                          </div>
                          <div class="grid grid-cols-1 md:grid-cols-2 items-center">
                              <label class="text-base font-sans font-bold">Correo </label>
                                 <label class="text-base font-sans font-bold text-gray-500 inline-block sm:overflow-auto md:overflow-hidden">{{profile.email ? profile.email : ''}} </label>
                           </div>
                      </div>
                  </div>
              </div>
              <div class="col-span-12 md:col-span-9 sm:px-5 md:px-10 sm:py-2 md:py-5 bg-white rounded-md shadow-lg">
                  <v-user-tabs />
              </div>
          </div>

          </div>
</template>

<script>
import RBButtonOptionsVue from '../../@core/components/form/button/RBButtonOptions.vue';
import {mapGetters } from "vuex";
import ToastMixin from '@/mixins/vue/toastmixin';
import GlobalMixin from '@/mixins/globalMixin';
import SecurityMixin from '@/mixins/security/RBSecurityMixin';
import indextabs from '../profileuser/tabs.vue';
import { ProfileService } from '../../services/HSuite/Context/hsuite-rebyu-context';
import vuserpicture from '../profileuser/picture.vue';
import actionCode from '../../utils/common/actions';

export default {
    mixins: [ToastMixin, GlobalMixin, SecurityMixin],
    components: {
        'v-user-tabs': indextabs,
        'v-user-picture': vuserpicture,
        'rb-button-options': RBButtonOptionsVue,
    },
    data: function() {
        return {
        isloading: false,
        isbadges: false,
        isprofilephoto: false,
        model: null,
        badges: null,
        profile: {},
        DEFAULT_IMG: 'https://res.cloudinary.com/dx3omcygd/image/upload/v1644090203/admin/de7834s-6515bd40-8b2c-4dc6-a843-5ac1a95a8b55_ionkae.jpg',
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init: async function() {
            this.isloading = true;
            await this.getProfile();
            this.isloading = false;
        },

        getProfile: async function() {
            let response = await ProfileService.getByIdUser();
            if(response.success) {
                this.profile = response.data;
            }else {
                this.failure(response.message);
            }
        },
        onSuccessPicture: function() {
            this.isprofilephoto = false;
            this.init();
        }

    },
    computed: {
      ...mapGetters({
        username: 'auth/attempt'
      }),
      actions: function() {
        let acc = [
                   {label: 'Actualizar', icon: 'edit', action: actionCode.EDIT, description: 'Actualiza los datos y fotos del usuario', acc: this.acc.EDIT_PROFILE, acm: this.$route.meta.acm},
        ]
        return acc
      }
    }

}
</script>

<style scoped>
.v-carousel {
    overflow: hidden;
    position: absolute;
    width: 100%;
}
</style>