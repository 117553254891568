<template>
  <div>
    <label class="text-base font-sans font-semibold">{{label ? label : ''}}</label>
        <slot name="input"></slot>
    <small class="text-error" v-show="value.$anyDirty && !value.required">* El campo es requerido</small>
  </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: ""
        },
        value: {
            type: [Object],
            default: function(){ return {}}
        }
    }
}
</script>

<style>

</style>