<template>
     <v-card class="w-full md:w-2/3 bg-white shadow-md rounded-sm h-auto sm:px-0 md:px-5">
        <v-card-title>
            <div class="flex gap-2 justify-between items-center">
                <div class="flex gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-gray-500">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                    </svg>
                    <span class="font-sans tracking-wide font-bold text-gray-500 text-base">INFORMACIÓN PERSONAL</span>
                </div>
                <div >
                    
                    <rb-button  @click.native="onEdit" v-if="!isEditPerson" :label="''" :css="'btn-full-fucsia'" class="flex justify-center gap-2 items-center py-3 shadow-md transition-all duration-200 ease-in-out text-white">
                        <template #default>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                            </svg>
                        </template>
                    </rb-button>
            
                
                    <rb-button @click.native="onSave" v-if="isEditPerson" :label="''" :css="'btn-full-fucsia'" class="flex justify-center gap-2 items-center py-3 shadow-md transition-all duration-200 ease-in-out text-white">
                        <template #default>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 3.75V16.5L12 14.25 7.5 16.5V3.75m9 0H18A2.25 2.25 0 0120.25 6v12A2.25 2.25 0 0118 20.25H6A2.25 2.25 0 013.75 18V6A2.25 2.25 0 016 3.75h1.5m9 0h-9" />
                            </svg>
                        </template>
                    </rb-button>
            
         </div>
            </div>
        </v-card-title>
        <hr>
        <v-card-text>
            <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5">
                 <div>
                      <rb-input-group :label="'Nombre(s)'" :value="$v.person.name">
                        <template #input>
                            <rb-input :disabled="!isEditPerson" :placeholder="'Ingrese el nombre'" :value="person.name" v-model="$v.person.name.$model" text @input="v => person.name = v"></rb-input>
                        </template>
                      </rb-input-group>
                  </div>
                  <div>
                      <rb-input-group :label="'Apellido paterno'" :value="$v.person.last_name">
                        <template #input>
                            <rb-input :disabled="!isEditPerson" :placeholder="'Ingrese el apellido'" :value="person.last_name" v-model="$v.person.last_name.$model" text @input="v => person.last_name = v"></rb-input>
                        </template>
                      </rb-input-group>
                  </div>
             </div>
             <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5">
                 <div>
                      <rb-input-group :label="'Apellido materno'" :value="$v.person.second_lastname">
                        <template #input>
                            <rb-input :disabled="!isEditPerson" :placeholder="'Ingrese el correo'" :value="person.second_lastname" v-model="$v.person.second_lastname.$model" text @input="v => person.second_lastname = v"></rb-input>
                        </template>
                      </rb-input-group>
                  </div>
                  <div>
                      <rb-input-group :label="'Fecha de nacimiento'" :value="$v.person.birthdate">
                        <template #input>
                            <div class="flex gap-2 items-center">
                                <masked-input :disabled="!isEditPerson" :class="{'bg-gray-50': !isEditPerson}" v-model="$v.person.birthdate.$model" :mask="'####-##-##'" type="text" class="input-full-fucsia" placeholder="Ingrese la fecha"/>
                                <rb-date-picker v-on:date="(val) => person.birthdate = val" :isdisabled="!isEditPerson" :datevalue="person.birthdate" />
                            </div>
                        </template>
                      </rb-input-group>
                  </div>
             </div>
             <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5">
                 <div>
                      <rb-input-group :label="'Género'" :value="$v.person.id_cat_sex">
                        <template #input>
                            <select id="large" v-model="$v.person.id_cat_sex.$model" class="input-full-fucsia" :disabled="!isEditPerson" :class="{'bg-gray-50 text-gray-700': !isEditPerson}">
                                <option selected disabled value="">Selecciona una opción</option>
                                <option v-bind:value="sex.id" class="py-2" v-for="(sex, i) in catsexcat" v-bind:key="i">{{sex.description ? sex.description : ''}}</option>
                            </select>
                           
                        </template>
                      </rb-input-group>
                  </div>
                  <div>
                      <rb-input-group :label="'Teléfono'" :value="$v.person.phone">
                        <template #input>
                            <masked-input :disabled="!isEditPerson" :class="{'bg-gray-50': !isEditPerson}" v-model="$v.person.phone.$model" mask="(111)-111-1111" maskChar=" " :masked="false" type="text" class="input-full-fucsia" placeholder="Ingrese el teléfono" />
                        </template>
                      </rb-input-group>
                  </div>
             </div>
           
           
        
        </v-card-text>
    </v-card>
</template>

<script>
import ToastMixin from '@/mixins/vue/toastmixin';
import GlobalMixin from '@/mixins/globalMixin'
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import RBInputPrimaryGroup from '../../../@core/components/form/group/RBInputPrimaryGroup.vue';
import RBPrimaryButton from '../../../@core/components/form/button/RBPrimaryButton.vue';
import RBInput from '../../../@core/components/form/input/RBInput.vue';
import Person from '../../../models/user/person.model';
import RBDatePickerVue from '../../../@core/components/form/menu/RBDatePicker.vue';
import { CatSexService, PersonService } from '../../../services/HSuite/Context/hsuite-core-context'

export default {
    mixins: [validationMixin, ToastMixin, GlobalMixin],
    data: function() {
        return {
            isEditPerson: false,
            person: new Person(),
            personTmp: new Person(),
            catsexcat : [],
        }
    },
    components: {
        'rb-input': RBInput,
        'rb-input-group': RBInputPrimaryGroup,
        'rb-button': RBPrimaryButton,
         'rb-date-picker': RBDatePickerVue,
    },
    validations: {
           
            person: {
                name: {
                    required
                },
                last_name: {
                    required
                },
                second_lastname: {
                    required
                },
                birthdate: {
                    required
                },
                id_cat_sex: {
                    required
                },
                desc_sex: {
                    required: false
                },
                phone: {
                    required: false
                },
        }
 
    },
    mounted() {
        this.init()
        this.$v.$reset()
    },
    methods: {
        init: async function() {
            await this.getPerson()
            await this.getCatSex()
        },
        getPerson: async function() {
                let response = await PersonService.getByUser();
                if(response.success) {
                    this.person = response.data;
                }
        },
        getCatSex: async function() {
                let response = await CatSexService.getAll();
                if(response.success) {
                    this.catsexcat = response.data;
                }
        },
        onEdit: async function() {
            let confirm = await this.confirm('Actualizar', '¿Desea actualizar su información personal?')
            if(!confirm) return;
            this.isEditPerson = !this.isEditPerson
            this.personTmp = {...this.person}
            console.log(this.personTmp)
        },
        onSave: async function() {
            let confirm = await this.confirm('Actualizar', '¿La información ingresada es correcta?')
            if(!confirm) { this.person = {...this.personTmp}; this.isEditPerson = false; return }

            this.$v.$touch();
            if(!this.$v.$anyError) {
                console.log(this.person)

                let response = await PersonService.update(this.person.id, this.person)
                if(response.success) {
                    this.toast(response.message ? response.message : '');
                    this.init()
                    this.isEditPerson = !this.isEditPerson
                }
               
            }

        }
    },
    watch: {
        isEditPerson: function() {
            if(this.isEditPerson) {
                this.$v.$reset()
               // this.init()
            }
        }
    }
}
</script>

<style>

</style>