import acc from '../../security/permissions/acc';
import acm from '../../security/modules/modules';

export default {
    data: function() {
        return {
            acc: acc,
            acm: acm
        }
    },
}