import Vue from 'vue';

export default Vue.extend({
    name: 'elevatable',

    props: {
        elevationsm: Boolean,
        elevationmd: Boolean
    },

    computed: {
        elevationClass() {
            return {
                'shadow-sm': this.elevationsm,
                'shadow-md': this.elevationmd
            }
        }
    }
})