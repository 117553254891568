<template>
  <div>
       <div class="flex gap-2 items-center py-1">
            <div class="rounded-full border-2 border-gray-500 px-2 py-0 text-gray-400">
                <span class="font-bold">1</span>
            </div>
            <div>
                <small class="font-sans text-gray-500 font-medium">Datos generales</small>
            </div>
       </div>
        <v-card-text>
            <div class="grid grid-cols-1 px-1 py-1 gap-5">
                 <div>
                      <rb-input-group :label="'Nombre'" :value="$v.address.name">
                        <template #input>
                            <rb-input :placeholder="'Ingrese el nombre de su dirección'" :value="address.name" v-model="$v.address.name.$model" text @input="v => address.name = v"></rb-input>
                        </template>
                      </rb-input-group>
                  </div>
             </div>
            <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5">
                 <div>
                      <rb-input-group :label="'País'" :value="$v.address.idCountry">
                        <template #input>
                            <v-auto-complete
                            v-model="$v.address.idCountry.$model"
                            :vmodel="address.idCountry"
                            :values="countries"
                            :vtext="'name'"
                            :vvalue="'id'"
                            :title="'name'"
                            :isdisabled="!countries"
                            />
                        </template>
                      </rb-input-group>
                  </div>
                  <div>
                      <rb-input-group :label="'Estado'" :value="$v.address.idState">
                        <template #input>
                            <v-auto-complete
                            v-model="$v.address.idState.$model"
                            :vmodel="address.idState"
                            :values="states"
                            :vtext="'name'"
                            :vvalue="'id'"
                            :title="'name'"
                            :isdisabled="!states"
                            />
                        </template>
                      </rb-input-group>
                  </div>
             </div>
             <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5">
                 <div>
                      <rb-input-group :label="'Municipio'" :value="$v.address.idMunicipality">
                        <template #input>
                            <v-auto-complete
                            v-model="$v.address.idMunicipality.$model"
                            :vmodel="address.idMunicipality"
                            :values="municipalities"
                            :vtext="'name'"
                            :vvalue="'id'"
                            :title="'name'"
                            :isdisabled="!municipalities"
                            />
                        </template>
                      </rb-input-group>
                  </div>
                  <div>
                      <rb-input-group :label="'Código Postal'" :value="$v.address.idPostalCode">
                        <template #input>
                            <v-auto-complete
                            v-model="$v.address.idPostalCode.$model"
                            :vmodel="address.idPostalCode"
                            :values="postcodes"
                            :vtext="'namecode'"
                            :vvalue="'id'"
                            :title="'namecode'"
                            :isdisabled="!postcodes"
                            />
                        </template>
                      </rb-input-group>
                  </div>
             </div>
             <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5">
                 <div>
                      <rb-input-group :label="'Colonia'" :value="$v.address.suburb">
                        <template #input>
                            <rb-input :placeholder="'Ingrese la colonia'" :value="address.suburb" v-model="$v.address.suburb.$model" text @input="v => address.suburb = v"></rb-input>
                        </template>
                      </rb-input-group>
                  </div>
                  <div>
                      <rb-input-group :label="'Calle'" :value="$v.address.street">
                        <template #input>
                            <rb-input :placeholder="'Ingrese la calle'" :value="address.street" v-model="$v.address.street.$model" text @input="v => address.street = v"></rb-input>
                        </template>
                      </rb-input-group>
                  </div>
             </div>
             <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5">
                 <div>
                      <rb-input-group :label="'Número Exterior'" :value="$v.address.outdoorNumber">
                        <template #input>
                            <rb-input :placeholder="'Ingrese el número'" :value="address.outdoorNumber" v-model.number="$v.address.outdoorNumber.$model" number @input="v => address.outdoorNumber = v"></rb-input>
                        </template>
                      </rb-input-group>
                  </div>
                  <div>
                      <rb-input-group :label="'Número Interior'" :value="$v.address.interiorNumber">
                        <template #input>
                            <rb-input :placeholder="'Ingrese el número'" :value="address.interiorNumber" v-model.number="$v.address.interiorNumber.$model" number @input="v => address.interiorNumber = v"></rb-input>
                        </template>
                      </rb-input-group>
                  </div>
             </div>
        
        </v-card-text>
        <div class="flex gap-2 items-center py-1">
            <div class="rounded-full border-2 border-gray-500 px-2 py-0 text-gray-400">
                <span class="font-bold">2</span>
            </div>
            <div>
                <small class="font-sans text-gray-500 font-medium">Ubicación</small>
            </div>
       </div>
       <div>
            <v-google-maps v-on:marker="addMarker" :vmarkers="addressMarker" />
       </div>
        <v-card-actions class="gap-2" >
            <v-spacer></v-spacer>
            <div>
                <rb-button :label="'Cancelar'" :css="'btn-full-gray rounded-md px-2'" small @click.native="$emit('cancel')"></rb-button>
            </div>
            <div>
                <rb-button :label="'Guardar'" :css="'btn-full-fucsia rounded-md px-2'" small @click.native="onSave"></rb-button>
            </div>
        </v-card-actions>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import globalMixin from '../../mixins/globalMixin';
import { required } from 'vuelidate/lib/validators';
import RBInputPrimaryGroup from '../../@core/components/form/group/RBInputPrimaryGroup.vue';
import RBPrimaryButton from '../../@core/components/form/button/RBPrimaryButton.vue';
import RBInput from '../../@core/components/form/input/RBInput.vue';
import Address from '../../models/user/address.model';
import { LocationService, AddressService } from '../../services/HSuite/Context/hsuite-core-context';

export default {
    mixins: [validationMixin, globalMixin],
    props: {
        id: {
            type: String,
            default: ''
        }
    },
    data: function() {
        return {
            countries: [],
            states: [],
            municipalities: [],
            postcodes: [],
            address: new Address(),
            isloading: false,
        }
    },
    validations: {
        address: {
             name: {
                 required
             },
             idCountry: {
                 required
             },
             idState: {
                 required
             },
             idMunicipality: {
                 required
             },
             idPostalCode: {
                 required
             },
             codePostal: {
                 required
             },
             suburb: {
                 required 
             },
             street: {
                 required
             },
             outdoorNumber: {
                 required
             },
             interiorNumber: {
                 required: false
             },
             coordinates: {
                 required: false
             },
             latitude: {
                 required
             },
             length: {
                 required
             }
         }
        
    },
    components: {
        'rb-input': RBInput,
        'rb-input-group': RBInputPrimaryGroup,
        'rb-button': RBPrimaryButton,
    },
    mounted() {
        if(this.id) {
            this.initEdit();
        }else {
            this.init()
            this.$v.$reset();
            this.address = new Address();
        }
    },
    methods: {
        initEdit: async function() {
            this.isloading = true;
            await this.getAddress(this.id)
            await this.getCountry();
            this.isloading = false;
        },
        init: async function() {
            this.isloading = true;
            await this.getCountry();
            this.isloading = false;
        },
        getAddress: async function(id) {
            let response = await AddressService.getById(id);
            if(response.success) {
                this.address = response.data;
            }
        },
        getCountry: async function() {
            let response = await LocationService.getCountry();
            if(response.success) {
                this.countries = response.data;
                this.address.idCountry =  this.countries[0].id;
            }
        },
        getState: async function(id) {
            this.isloading = true;
            let response = await LocationService.getState(id);
            this.isloading = false;
            if(response.success) {
                this.states = response.data;
            }
        },
        getMunipality: async function(id) {
            this.isloading = true;
            let response = await LocationService.getMunicipality(id);
            this.isloading = false;
            if(response.success) {
                this.municipalities = response.data;
            }
        },
        getPostalCodes: async function(id, code) {
            this.isloading = true;
            let response = await LocationService.getPostalCodes(id, code);
            this.isloading = false;
            if(response.success) {
                this.postcodes = response.data;
            }
        },
        addMarker(event) {
            const {lat, lng} = event;
             this.address.coordinates = lat.toString() + ',' +lng.toString();
             this.address.latitude = lat.toString();
             this.address.length = lng.toString();
         },
         onSave: async function() {
            this.$v.$touch();
            console.log(this.address, this.$v)
            if(!this.$v.$anyError) {
                if(this.id) {
                    await this.update()
                }else {
                    await this.save()
                }
            }
         },
         save: async function() {
            let response = await AddressService.save(this.address);
            if(response.success) {
                this.toast(response.message);
                this.$emit('success')
            }
         },
         update: async function() {
            let response = await AddressService.update(this.id, this.address);
            if(response.success) {
                this.toast(response.message);
                this.$emit('success')
            }
         }
    },
    computed: {
        addressMarker: function() {
            return this.address.latitude && this.address.length ? {lat: parseFloat(this.address.latitude), lng: parseFloat(this.address.length)} : {}
        },
        mucipality_code: function() {
            let code = this.municipalities.find(x => x.id == this.address.idMunicipality);
            return code ? code.code : '';
        },
    },
    watch: {
        id: function() {
            if(this.id) {
                this.initEdit()
            }else {
                this.init()
                this.$v.$reset();
                this.address = new Address();
            }
        },
        'address.idCountry' : function() {
             if(!this.address.idCountry) {
                this.states = [];
            }
            if(this.address.idCountry) {
                this.getState(this.address.idCountry);
            }
        },
        'address.idState' : function() {
             if(!this.address.idState) {
                this.municipalities = [];
                this.postcodes = [];
                this.address.idMunicipality = '';
                this.codePostal = '';
                this.idPostalCode = '';
            }
            if(this.address.idState) {
                this.getMunipality(this.address.idState);
            }
        },
        'address.idMunicipality': function() {
             if(!this.address.idMunicipality) {
                this.postcodes = [];
                this.codePostal = '';
                this.idPostalCode = '';
            }
            if(this.address.idMunicipality) {
                this.getPostalCodes(this.address.idMunicipality, this.mucipality_code ? this.mucipality_code : this.address.mucipality_code);
            }
        },
        'address.idPostalCode' : function() {
             if(!this.address.idPostalCode) {
                 this.address.suburb = '';
            }
            if(this.address.idPostalCode && !this.address.suburb && !this.address.codePostal) {
                let code = this.postcodes.find(x => x.id == this.address.idPostalCode);
                this.address.suburb = code ? code.name : '';
                this.address.codePostal = code ? code.code : '';
            }
        }
    }
}
</script>

<style scoped>

.v-input--selection-controls {
    margin-top: 5px !important;
    padding-top: 2px !important;
}

.v-input__slot {
    align-items: center;
    color: inherit;
    display: flex;
    margin-bottom: 0px !important;
    min-height: inherit;
    position: relative;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    width: 100%;
}

</style>