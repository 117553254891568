<template>
    <button  type="button" class="focus:outline-none  btn-full-gray  bg-transparent hover:bg-transparent" :class="bntcss">
        
        <svg  xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" :class="iconcss"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path  stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
    </svg>
    </button>
</template>

<script>
export default {
    props: {
        bntcss: {
            type: String,
            default: ''
        },
        iconcss: {
            type: String,
            default: ''
        }
    },
    computed: {
        
    }
}
</script>

<style>

</style>