import Vue from 'vue';

export default Vue.extend({
    name: 'sizeable',
    
    props: {
        small: Boolean,
        large: Boolean,
        xLarge: Boolean,
        xSmall: Boolean,
    },
    computed: {
        sizeableClass() {
            return {
                'px-1 py-0': this.small,
                'px-2 py-1': this.large
            }
        },
        sizeableModalClass() {
            return {
                'px-3 py-1': this.small,
                'px-3 py-2': this.large
            }
        }
    }
})