<template>
    <div>
        <input :disabled="disabled" :type="type" :value="value" @input="$emit('input', $event.target.value)" class="input-full-fucsia" :class="{'bg-gray-50': disabled}"  :placeholder="placeholder" step="any" />
    </div>
</template>

<script>
import typeable from '../../../mixins/typeable';

export default {
    name: 'rb-input',
    mixins: [typeable],
    props: {
        value: {
            type: [String, Number, Image],
            default: ""
        },
        placeholder:  {
            type: String,
            default: ""
        },
        disabled: Boolean
    },
    computed: {
        type() {
           let types = {...this.typeableClass}
            return Object.keys(types).find(key => types[key] == true)
        },
    },

}
</script>

<style>

</style>