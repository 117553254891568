<template>
   <div class="h-64 sm:w-full md:w-72 bg-gray-800 rounded-md shadow-md transition duration-200 hover:shadow-lg relative">
        <div :class="{'bg-gray-700': !entity.active, 'bg-gray-300 hover:bg-gray-50': entity.active}" class="h-1/2 bg-blend-multiply    rounded-tl-lg rounded-tr-lg bg-cover relative" :style="`background-image: url('`+entity.coverPhoto+`')`"></div>
        <div class="w-20 h-20 rounded-full border-2 border-gray-100 absolute top-1/3 right-5 flex justify-center items-center">
            <div class="w-16 h-16 bg-gray-300 rounded-full bg-cover" :style="`background-image: url('`+entity.profilePicture+`')`"></div>
        </div>
         <div  class="absolute top-1 text-xs left-1  tracking-wider text-white bg-red-500 px-1 rounded-sm">
            <span v-if="!entity.active" class="m-1 font-bold">NO VISIBLE</span>
         </div>
         <div class="absolute top-1 right-2 flex justify-end gap-2 w-full text-white">
            <div class="contents w-5 justify-end gap-1 items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-eye-check text-white" width="18" height="18" viewBox="0 0 24 24" stroke-width="1.5" stroke="#9e9e9e" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                    <path d="M11.143 17.961c-3.221 -.295 -5.936 -2.281 -8.143 -5.961c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6c-.222 .37 -.449 .722 -.68 1.057" />
                    <path d="M15 19l2 2l4 -4" />
                </svg>
                <small class="font-sans">{{entity.visits ? entity.visits : '0'}}</small>
            </div>
            <span class="text-gray-500">/</span>
            <div class="contents justify-end gap-1 items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-description"  width="18" height="18" viewBox="0 0 24 24" stroke-width="1.5" stroke="#9e9e9e" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                    <path d="M9 17h6" />
                    <path d="M9 13h6" />
                </svg>
                <small class="font-sans">{{entity.reviews ? entity.reviews : '0'}}</small>
            </div>
         </div>
      
            <div class="mt-9 px-3 gap-y-3 grid  cursor-pointer">
                <label class="text-white font-sans font-bold text-2xl break-all overflow-ellipsis truncate cursor-pointer">{{entity.name ? entity.name : ''}}</label>
                <div class="flex justify-between items-center gap-2">
                    <router-link v-if="entity.id" :to="`/entity/admin/${entity.id}`" class="w-full">
                        <button class="w-full px-5 bg-white rounded-md shadow-md text-fucsia-lighter font-sans text-sm font-semibold py-1 hover:shadow-lg">Explorar</button>
                    </router-link>
                    <rb-options :actions="actions" v-on:event="(event) => onEvent(event, entity)">
                        <template #buttonsheet>
                            <button  type="button" class="focus:outline-none  w-min flex md:hidden bg-white opacity-90 px-5 py-0.5 rounded-md justify-center">
                                <svg  xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path  stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
                                </svg>
                            </button>
                        </template>
                        <template #buttonmenu>
                            <button  type="button" class="focus:outline-none  hidden md:flex w-full bg-white opacity-90 px-5 py-0.5 rounded-md justify-center">
                                <svg  xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path  stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
                                </svg>
                            </button>
                        </template>
                    </rb-options>
                </div>
            </div>
        

    </div>

</template>

<script>
import SecurityMixin from '@/mixins/security/RBSecurityMixin';
import globalMixin from '@/mixins/globalMixin';
import toastMixin from '@/mixins/vue/toastmixin';
import actions from '../../../utils/common/actions';
import { EntityService } from '../../../services/HSuite/Context/hsuite-rebyu-context'

export default {
    mixins: [globalMixin, toastMixin, SecurityMixin],
    props : {
        entity: {
            type: Object,
            default:function() {return {}}
        }
    },
    components: {
    },
    data: function() {
        return {
              
        }
    },
    methods: {
         onEvent: function (event, item) {
            switch(event) {
                case actions.PREVIEW:
                    this.$router.push(`/${item.tagname}`)
                break;
                case actions.DISABLED:
                    this.onEnabledStore(item.id, false);
                break;
                case actions.ENABLED:
                    this.onEnabledStore(item.id, true);
                break;
               case actions.EDIT:
                      this.$router.push(`/entity/edit/${item.id}`)
                break;
                case actions.QRCODE:
                      this.GenerateQr(item);
                break;
            }
        },
        onEnabledStore: async function(id, status) {
            let confirm = await this.confirm(`${!status ? 'Ocultar Tienda' : 'Mostrar Tienda'}`, `${!status ? '¿Deseas ocultar la tienda a los usuarios?, esta acción se puede revertir' : '¿Desea hacer disponible la tienda a los usuarios?'}` );
            if(confirm) {
                this.isloading = true;
                let data = { active: status}
                let response = await EntityService.status(id, data);
                this.isloading = false;
                if(response.success) {
                    this.success(response.message);
                    this.$emit('succesevent');
                }else {
                    this.failure(response.message);
                }
            }
        },
        GenerateQr : function(){

            this.iscreateQR = true
        },
        onsuccess : function(){
            this.iscreateQR = false

        },
    },
    computed: {
        actions: function() { 
            let actionentity =  [
                {label: 'Previsualizar', icon: 'store', action: actions.PREVIEW, 
                    description: 'Obten una vista prevía de tu tienda', acm: this.acm.ENTITYDETAIL.name, acc: this.acc.PREVIEW_ENTITY},
                {label: 'Actualizar', icon: 'edit', action: actions.EDIT, 
                    description: 'Actualiza los datos y fotos de tu tienda', acm: this.acm.ENTITYDETAIL.name, acc: this.acc.UPDATE_ENTITY},
                {label: this.entity.active ? 'Ocultar' : 'Mostrar', icon: 'eye-slash', 
                    action:  this.entity.active? actions.DISABLED : actions.ENABLED, 
                    description: 'Oculta temporalmente tu tienda', acm: this.acm.ENTITYDETAIL.name, acc: this.acc.HIDDEN_ENTITY },
                {label: 'Eliminar', icon: 'trash-alt', action: actions.DELETE, 
                    description: 'Elimina permanentemente tu tienda', acm: this.acm.ENTITYDETAIL.name, acc: this.acc.DELETE_ENTITY},
            ]
            return actionentity;
        }
    }
}
</script>

<style>

</style>