<template>
  <div class="sm:px-0 md:px-5 py-3 grid grid-cols-1 justify-center justify-items-center gap-y-5">

   
    <rb-settings-user />

    <rb-settings-person />

    <rb-settings-address />
   
        
  </div>
</template>

<script>

import settingsAddressVue from './components/settings.address.vue';
import settingsUserVue from './components/settings.user.vue';
import settingPersonVue from './components/settings.person.vue';
   
export default {
   
    components: {
        'rb-settings-address': settingsAddressVue,
        'rb-settings-user': settingsUserVue,
        'rb-settings-person': settingPersonVue,
      },

}
</script>


<style scoped>


</style>

