export default class Address {
    constructor(
        id_user,
        idCountry,
        idState,
        idMunicipality,
        idPostalCode,
        codePostal,
        suburb,
        street,
        outdoorNumber,
        interiorNumber,
        coordinates,
        latitude,
        length,
        name
    ) {
        this.id_user = id_user ? id_user : '',
        this.idCountry = idCountry ? idCountry : '',
        this.idState = idState ? idState : '',
        this.idMunicipality = idMunicipality ? idMunicipality : '',
        this.idPostalCode = idPostalCode ? idPostalCode : '',
        this.codePostal = codePostal ? codePostal : '',
        this.suburb = suburb ? suburb : '',
        this.street = street ? street : '',
        this.outdoorNumber = outdoorNumber ? outdoorNumber :  '',
        this.interiorNumber = interiorNumber ? interiorNumber : '',
        this.coordinates = coordinates ? coordinates : '',
        this.latitude = latitude ? latitude : '',
        this.length = length ? length : '',
        this.name = name ? name : ''
    }
}