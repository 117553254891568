export default class Person {
    constructor(
        name,
        last_name,
        second_lastname,
        birthdate,
        id_cat_sex,
        desc_sex,
        phone
    ) {
        this.name = name ? name : ''
        this.last_name = last_name ?  last_name : ''
        this.second_lastname = second_lastname ? second_lastname : ''
        this.birthdate = birthdate ? birthdate : ''
        this.id_cat_sex = id_cat_sex ? id_cat_sex : ''
        this.desc_sex = desc_sex ? desc_sex : ''
        this.phone = phone ? phone : ''
}

}