<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="true"
        :return-value.sync="date"
        transition="scale-transition"
        lang="es-MX"
        width="290px"
        :disabled="isdisabled"
    >
        <template v-slot:activator="{ on, attrs }">
            <div  v-bind="attrs" v-on="on">
                <svg   xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-calendar cursor-pointer text-gray-400 hover:text-control-dark-blue-light" viewBox="0 0 16 16">
                    <path  d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                </svg>
            </div>
        </template>
        <v-date-picker
        v-model="date"
        no-title
        scrollable
        landscape
        header-color="blue darken-4"
        @change="$refs.menu.save(date)"
        >
        <!-- <v-spacer></v-spacer>
        <v-btn
            text
            color="primary"
            @click="menu = false"
        >
            Cancelar
        </v-btn>
        <v-btn
            text
            color="primary"
            @click="$refs.menu.save(date)"
        >
            Aceptar
        </v-btn> -->
        </v-date-picker>
    </v-menu>
</template>

<script>


export default {
    props: {
        isdisabled: {
            type: Boolean,
            default: false
        },
        datevalue: {
            type: String,
            default: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString()
        }
    },
    data: function() {
        return {
           // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString(),
            menu: false,
            dateprop: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString()
        }
    },
    computed: {
        date: {
           get: function() {
                return this.dateprop ? this.dateprop : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString()
           },
           set: function(val) {
              this.dateprop = val
           }
        }
    },
    watch: {
        date: function() {
            //this.profile.person.birthdate = this.date;
            this.$emit('date', this.date)
        },
        datevalue: function() {
            if(this.datevalue) {
                this.dateprop = this.datevalue;
            }
        }
    }
}
</script>

<style>

</style>