export default class ProfileUserPicture {
    constructor(
        id_profile,
        name,
        profile_picture,
        public_id
    ) {
        this.id_profile = id_profile ? id_profile : '',
        this.name = name ? name : '',
        this.profile_picture = profile_picture ? profile_picture : '',
        this.public_id = public_id ? public_id : ''
    }
}