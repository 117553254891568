<template>
  <v-card>
      <v-loading :is_loading="isloading" />
       <v-modal-title v-on:close="$emit('close')">
            Foto de Perfil
       </v-modal-title>
        <v-divider></v-divider>
        <v-card-text class="py-2">
            <div v-if="!image" class="bg-gray-100 px-5 text-gray-500 font-sans rounded-md">
             Seleccione un imagen para continuar
         </div>
            <cropper
              ref="cropper"
                class="cropper mt-2"
                :src="image"
                :transitions="true"
                image-restriction="fit-area"
                :min-height="height"
                :min-width="width"
            >
            </cropper>
        <div class="mt-2 flex gap-5 justify-center items-center">
            <v-btn
                color="pink"
                dark
                fab
                @click="$refs.file.click()"
                >
                <input ref="file" type="file" class="hidden" accept="image/*" @change="uploadImage($event)" />
                <v-icon>mdi-cloud-upload</v-icon>
            </v-btn>
                <v-btn
                color="red"
                dark
                fab
                @click="removeFile"
                >
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </div>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
            <button :disabled="isloading"
                class="bg-gray-200 text-gray-500 text-lg tracking-wide mr-2 px-3 py-1 rounded-lg shadow-sm hover:bg-gray-500 hover:text-white focus:outline-none" 
                @click="$emit('close')">Cerrar</button>
            <button :disabled="isloading"
                class="bg-fucsia-lighter text-white text-lg tracking-wide mr-2 px-3 py-1 rounded-lg shadow-sm hover:bg-fucsia-dark focus:outline-none" 
               @click="save" >Guardar</button>
        </v-card-actions>
  </v-card>
</template>

<script>
import ProfileUserPicture from '../../models/profile/profileuserpicture.model';
import toastMixin from "@/mixins/vue/toastmixin";
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import {Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { ProfilePictureService } from '../../services/HSuite/Context/hsuite-rebyu-context';
import cloudStorageService from '../../services/firebase/cloudstorage.service';
 import globalMixin from '@/mixins/globalMixin';
 import file from '../../utils/file/file';
 import commoncontext from '../../utils/common/firebasecontext';

export default {
    mixins: [toastMixin, validationMixin, globalMixin],
    props: {
        open: {
            type: Boolean,
            default: false
        },
        id_profile: {
            type: String,
            default: ""
        },
        height: {
            type: Number,
            default: 500
        },
        width: {
            type: Number,
            default: 500
        }
     },
    components: {
        Cropper
    },
    data : function () {
        return {
            isloading: false,
            profile: new ProfileUserPicture(),
            image: '',
            images: {
                profilePictureFile: ''
            }
        }
    },
    validations: {
        profile : {
           
            id_profile: {
                required
            },
            profile_picture: {
                required: false
            },
            public_id: {
                required: false
            }
        },
        images: {
             profilePictureFile: {
                required
            }
        }
    },
    methods : {
        save : async function () {
             if(this.$refs.cropper.getResult().canvas) {
                 this.images.profilePictureFile = this.$refs.cropper.getResult().canvas.toDataURL();
                 this.profile.id_profile = this.id_profile;
                 this.$v.$touch();
                 if(!this.$v.$anyError) {
                     this.isloading = true;
                     const blob = await file.b64toBlob(this.images.profilePictureFile, 'image/jpeg');
                     const responseCloud = await cloudStorageService.uploadFile(commoncontext.USER, blob, this.id_profile);
                     this.profile.profile_picture = responseCloud.url;
                     this.profile.fullpath = responseCloud.fullPath;
                     this.profile.public_id = responseCloud.public_id;


                     let response = await ProfilePictureService.save(this.profile);
                     this.isloading = false;
                     if(response.success) {
                         this.$emit('success');
                     }else {
                         this.isloading = false;
                         this.failure(response.message);
                     }
                 }
             }else {
                    this.failure('Seleccione una imagen');
            }

        },
         uploadImage(event) {
			// Reference to the DOM input element
			var input = event.target;
			// Ensure that you have a file before attempting to read it
			if (input.files && input.files[0]) {
				// create a new FileReader to read this image and convert to base64 format
				var reader = new FileReader();
				// Define a callback function to run, when FileReader finishes its job
				reader.onload = (e) => {
					// Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
					// Read image as base64 and set to imageData
					this.image = e.target.result;
				};
				// Start the reader job - read file as a data url (base64 format)
				reader.readAsDataURL(input.files[0]);
			}
		},
        removeFile() {
              this.image = null;
             this.$refs.file.value = '';
       }
    },
    watch: {
         open: function() {
            if(this.open) {
                this.$refs.file.value = '';
                this.$v.$reset();
                this.profile = new ProfileUserPicture();
                this.image = '';
            }
        },
    }
}
</script>

<style scoped>
.cropper {
	height: 500px;
	background: #DDD;
}
</style>