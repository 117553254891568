<template>
  <div class="h-full">
        <v-tabs
          v-model="tab"
          background-color="blue-grey lighten-5"
        >
          <v-tabs-slider color="blue"></v-tabs-slider>

          <v-tab
            v-for="item in itemstabs"
            :key="item"
            dark
          >
            {{ item }}
          </v-tab>
        </v-tabs>
         <v-tabs-items v-model="tab">
             <v-tab-item >
                 <div class="py-5 grid gap-y-2 h-full">
                    
                        <div class="flex justify-between  items-center sm:px-1 md:px-5">
                            <label class="font-sans font-bold tracking-wide text-gray-500 text-2xl">Mis Entidades</label>
                             <router-link to="/entity/new">
                             <v-btn
                                color="pink"
                                dark
                                fab
                                small
                                v-security="{acm: $route.meta.acm, acc: acc.CREATE_ENTITY}"
                                >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                             </router-link>
                        </div>
                            <v-divider></v-divider>
                        <div class="flex flex-wrap gap-5 gap-y-5 px-0">
                                <v-entity-card v-on:succesevent="onsuccessevent" :entity="entity"  v-for="(entity, i) in entities" :key="i" />
                              <div v-if="!entities || isLoading" class="flex items-center">
                                  <v-skeleton  class="w-72 h-60" v-for="x in [1,2,3]" v-bind:key="x"></v-skeleton>
                                </div>

                        </div>

                          <div class="flex justify-between  items-center sm:px-1 md:px-5">
                            <label class="font-sans font-bold tracking-wide text-gray-500 text-2xl">Colaboraciones</label>
                             <router-link to="/entity/new">
                             <v-btn
                                color="pink"
                                dark
                                fab
                                small
                                v-security="{acm: $route.meta.acm, acc: acc.CREATE_ENTITY}"
                                >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                             </router-link>
                        </div>
                            <v-divider></v-divider>

                    
                 </div>
             </v-tab-item>
            <v-tab-item >
                 <v-user-setting v-if="tab == 1" />
             </v-tab-item>
             <v-tab-item>
                organización
             </v-tab-item>
         </v-tabs-items>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ToastMixin from '@/mixins/vue/toastmixin';
import GlobalMixin from '@/mixins/globalMixin';
import SecurityMixin from '@/mixins/security/RBSecurityMixin';
import entitycard from "./components/entity.card.vue";
import userSetting from './settings.vue';
import {MODULES, TYPES} from '../../store/common/HMVuexCommon';

export default {
    mixins: [ToastMixin, GlobalMixin, SecurityMixin],
    components: {
        'v-entity-card' : entitycard,
        'v-user-setting' : userSetting,
    },
    data: function() {
        return {
            tab: null,
            itemstabs: ['Entidades','Perfil', 'Organización'],
            isLoading: false
        }
    },
    mounted() {
      this.init();
    },
    computed: {
      ...mapGetters( MODULES.ENTITY, {
           entities: TYPES.RB_ENTITY_GET_ALL
      }),
    },
    methods: {
      init: async function () {
        this.isLoading = true;
        await this.getEntities();
        this.isLoading = false;
      },
      ...mapActions(MODULES.ENTITY, [
            TYPES.RB_ENTITY_GET_ALL
      ]),
      onsuccessevent: function() {
        this.init();
      }
    },
    
}
</script>
