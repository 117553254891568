<template>
   <v-card class="w-full md:w-2/3 bg-white shadow-md rounded-sm h-auto sm:px-0 md:px-5 py-3">
        <v-card-title>
            <div class="flex gap-2 justify-between items-center">
                <div class="flex gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-gray-500">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                    </svg>
                    <span class="font-sans tracking-wide font-bold text-gray-500 text-base">DIRECCIONES</span>
                </div>
                <div >
                    
                    <rb-button  @click.native="onNew" v-if="!isEditAddress" :label="''" :css="'btn-full-fucsia'" class="flex justify-center gap-2 items-center py-3 shadow-md transition-all duration-200 ease-in-out text-white">
                        <template #default>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                             <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                        </template>
                    </rb-button>
            </div>
            </div>
        </v-card-title>
        <hr>

        <v-row class="center py-5 px-5" v-if="!isEditAddress">
            <v-expansion-panels accordion flat>
            <v-expansion-panel
                v-for="(item,i) in addresses"
                :key="i"
                :class="{'border border-gray-200': !item.ultimo, 'border border-green-500': item.ultimo}"
            >
                <v-expansion-panel-header>
                    <div class="grid gap-y-2">
                        <div class="flex gap-2 items-center">
                            <div class="rounded-full border px-1 py-1" :class="{'border border-gray-200': !item.ultimo, 'border border-green-500': item.ultimo}">
                                <svg v-if="item.ultimo" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-4 h-4 absolute bg-green-500 rounded-full text-white left-12 top-3">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>

                                <img src="/img/address/address-check.png" alt="address" width="30" height="30" />
                            </div>
                            <span  class="font-sans font-bold" :class="{'text-green-500': item.ultimo, 'text-gray-700': !item.ultimo}">{{item.name ? item.name : 'Sin nombre' }}</span>
                        </div>
                        <span  class="font-sans text-gray-500 font-medium">{{item.address ? item.address : '' }}</span>
                        <span  class="font-sans text-gray-500 font-medium">C.P. {{item.code_postal_code ? item.code_postal_code : '' }}</span>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="flex justify-end gap-2 py-2">
                       <div>
                        <rb-button :label="''" :css="'btn-full-gray px-2 py-2 rounded-md'" small @click.native="onDelete(item.id)">
                            <template #default>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                </svg>
                            </template>
                        </rb-button>
                       </div>
                       <div v-if="!item.ultimo">
                           <rb-button :label="''" :css="'btn-full-fucsia px-2 py-2 rounded-md bg-purple-500 hover:bg-purple-600'" small @click.native="onsetDefault(item.id)">
                               <template #default>
                                   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                       <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                   </svg>
                               </template>
                           </rb-button>
                       </div>
                       <div>
                        <rb-button :label="''" :css="'btn-full-fucsia px-2 py-2 rounded-md'" small @click.native="onEdit(item.id)">
                            <template #default>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                </svg>

                            </template>
                        </rb-button>
                       </div>
                    </div>
                    <rb-place-map :location="item.latitude && item.length ? item.latitude+','+item.length : item.desc_state ? item.desc_state : 'Chiapas'"/>
                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>
        </v-row>

        <div v-if="isEditAddress">
            <rb-address-form :id="idAddressSelected" v-on:cancel="isEditAddress = false" v-on:success="onSuccess" />
        </div>

    </v-card>
</template>

<script>
import { validationMixin } from 'vuelidate';
import globalMixin from '../../../mixins/globalMixin';
import RBPrimaryButton from '../../../@core/components/form/button/RBPrimaryButton.vue';
import {  AddressService } from '../../../services/HSuite/Context/hsuite-core-context';
import RBAddressFormVue from '../../address/RBAddressForm.vue';
export default {
    mixins: [validationMixin, globalMixin],
    data: function() {
        return {
            isEditAddress: false,
            addresses: [],
            isloading: false,
            idAddressSelected: ''
        }
    },
    validations: {
       
        
    },
    components: {
        'rb-button': RBPrimaryButton,
        'rb-address-form': RBAddressFormVue,
    },
    mounted() {
        this.init();
    },
    methods: {
        init: async function() {
            this.isloading = true;
            await this.getAddresses();
            this.isloading = false;
        },
        getAddresses: async function() {
            let response = await AddressService.getByUser();
            if(response.success) {
                this.addresses = response.data;
            }
        },
        onEdit: function(id) {
            this.idAddressSelected = id;
            this.isEditAddress = true;
        },
        onNew: function() {
            this.idAddressSelected = '';
            this.isEditAddress = true;
        },
        onSuccess: function() {
            this.isEditAddress = false;
            this.init()
        },
        onsetDefault: async function(id) {
            let confirm = await this.confirm('Predeterminado', '¿Desea establecer esta dirección como predeterminada?')
            if(!confirm) return;

            let data = {option:  3}

            let response = await AddressService.status(id, data);
            if(response.success) {
                this.toast(response.message ? response.message : '');
                this.init()
            }

        },
        onDelete: async function(id) {
            let confirm = await this.confirm('Eliminar', '¿Desea eliminar la dirección seleccionada?')
            if(!confirm) return;

            let data = {option:  2}

            let response = await AddressService.status(id, data);
            if(response.success) {
                this.toast(response.message ? response.message : '');
                this.init()
            }

        }
       
    },
   
}
</script>
