const PROFILE_PHOTO = 'PROFILE_PHOTO';
const CREATE_ENTITY = 'CREATE_ENTITY';
const EDIT_PROFILE = 'EDIT_PROFILE';
const ADD_ADDRESS = 'ADD_ADDRESS';
const PREVIEW_ENTITY = 'PREVIEW_ENTITY';
const UPDATE_ENTITY = 'UPDATE_ENTITY';
const HIDDEN_ENTITY = 'HIDDEN_ENTITY';
const DELETE_ENTITY = 'DELETE_ENTITY';
const EDIT_PROFILE_PHOTO_ENTITY = 'EDIT_PROFILE_PHOTO_ENTITY';
const EDIT_COVER_PHOTO_ENTITY = 'EDIT_COVER_PHOTO_ENTITY';
const ADD_TAGS = 'ADD_TAGS';
const ADD_ADDRESS_ENTITY = 'ADD_ADDRESS_ENTITY';
const EDIT_ADDRESS = 'EDIT_ADDRESS';
const ADD_PRODUCT = 'ADD_PRODUCT';
const SHOW_PRODUCT = 'SHOW_PRODUCT';
const EDIT_PRODUCT = 'EDIT_PRODUCT';
const DELETE_PRODUCT = 'DELETE_PRODUCT';

export default {
    PROFILE_PHOTO,
    CREATE_ENTITY,
    EDIT_PROFILE,
    ADD_ADDRESS,
    PREVIEW_ENTITY,
    UPDATE_ENTITY,
    HIDDEN_ENTITY,
    DELETE_ENTITY,
    EDIT_PROFILE_PHOTO_ENTITY,
    EDIT_COVER_PHOTO_ENTITY,
    ADD_TAGS,
    ADD_ADDRESS_ENTITY,
    EDIT_ADDRESS,
    ADD_PRODUCT, 
    SHOW_PRODUCT,
    EDIT_PRODUCT,
    DELETE_PRODUCT
}