<template>
    <button :class="[css, classes]" >
        {{label ? label : ''}}
        <slot></slot>
  </button>
</template>

<script>
import sizeable from '../../../mixins/sizeable';
import elevatable from '../../../mixins/elevatable';

export default {
    mixins: [sizeable, elevatable],
    props: {
        label: {
            type: String,
            default: "text"
        },
        css: {
            type: String,
            default: ""
        },
        modal: Boolean,
        normal: Boolean
    },
    computed: {
        classes() {
            let sizeableNormal = {...this.sizeableClass}
            let sizeableModal = {...this.sizeableModalClass}
            let sizeClass = this.modal ? sizeableModal : this.normal ? sizeableNormal : sizeableNormal;
            return {...sizeClass , ...this.elevationClass}
        },
    }
}
</script>

<style>

</style>